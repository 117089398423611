#menu {
  z-index: theme(zIndex.auto);
}

#menu .menu-container {
  background-color: theme(colors.neutral.200);
  color: theme(colors.gray.600);
  height: theme(height.full);
  margin-bottom: theme(margin.16);
  position: relative;
  width: theme(width.60);
}

/* Menu list */
#menu .menu-container .list-container {
  height: theme(height.full);
  position: relative;
  width: theme(width.full);
}

#menu .menu-container ul {
  position: relative;
  font-weight: theme(fontWeight.semibold);
}

#menu .menu-container ul li {
  cursor: pointer;
}

#menu .menu-container ul li > .page-title,
#menu .menu-container ul li > * > .page-title {
  justify-content: space-between;
  padding: theme(padding.3);
  align-items: center;
  animation-duration: 75ms;
  display: flex;
  gap: 12px;
}

/* Level 0 */
#menu .menu-container ul[class='level-0'] {
  font-size: theme(fontSize.base);
  background-color: theme(colors.neutral.200);
}

#menu .menu-container ul[class='level-0'] > li {
  border-bottom: 2px solid white;
}

#menu .menu-container ul[class='level-0'] > li > details > * > i {
  color: transparent;
}

#menu .menu-container ul[class='level-0'] > li:is(.active) > details > * > i,
#menu .menu-container ul[class='level-0'] > li:is(.active, :hover) > .page-title,
#menu .menu-container ul[class='level-0'] > li:is(.active, :hover) > * > .page-title {
  background-color: theme(colors.brand.green500);
  color: white;
}

#menu .menu-container ul[class='level-0'] > li > .page-title,
#menu .menu-container ul[class='level-0'] > li > * > .page-title {
  padding-left: theme(padding.4);
  min-height: theme(height.16);
}

/* Level 1 */
#menu .menu-container ul[class='level-1'] {
  font-size: theme(fontSize.sm);
  background-color: theme(colors.neutral.100);
}

#menu .menu-container ul[class='level-1'] > li:is(.active, :hover) > .page-title,
#menu .menu-container ul[class='level-1'] > li:is(.active, :hover) > * > .page-title {
  border-color: theme(colors.brand.green500);
  color: theme(colors.brand.green500);
}

#menu .menu-container ul[class='level-1'] > li > .page-title,
#menu .menu-container ul[class='level-1'] > li > * > .page-title {
  padding-left: theme(padding.7);
  border: 2px solid transparent;
  min-height: theme(height.12);
}

/* Level 2 and others */
#menu .menu-container ul.level-2 {
  background-color: theme(colors.neutral.50);
  font-size: theme(fontSize.sm);
}

#menu .menu-container ul.level-2 > li:is(.active, :hover) > .page-title,
#menu .menu-container ul.level-2 > li:is(.active, :hover) > * > .page-title {
  text-decoration: underline;
}

#menu .menu-container ul.level-2 li > .page-title,
#menu .menu-container ul.level-2 li > * > .page-title {
  padding-left: theme(padding.10);
  padding-right: theme(padding.5);
  min-height: theme(height.10);
}
