.dx-texteditor.dx-state-hover {
  border-color: theme(colors.brand.green500);
}

.dx-list-item.dx-state-active {
  background-color: theme(colors.brand.gray100) !important;
  color: theme(colors.black) !important;
}

.dx-list-group-header:empty {
  padding: 0%;
  border: 0;
}