/* TOAST */
.custom-toast > *[class*='content'] {
  width: 95vw !important;
  border-radius: 0;
  background-color: white;
  border: 2px solid;
}

.custom-toast.toast-type-error > *[class*='content'] {
  color: theme(colors.feedback.error);
  border-color: theme(colors.feedback.error);
}

.custom-toast.toast-type-info > *[class*='content'] {
  color: theme(colors.feedback.info);
  border-color: theme(colors.feedback.info);
}

.custom-toast.toast-type-warning > *[class*='content'] {
  color: theme(colors.feedback.warning);
  border-color: theme(colors.feedback.warning);
}

.custom-toast.toast-type-success > *[class*='content'] {
  color: theme(colors.feedback.success);
  border-color: theme(colors.feedback.success);
}

@media only screen and (min-width: theme(screens.lg)) {
  .custom-toast > *[class*='content'] {
    width: 60vw !important;
  }
}

@font-face {
  font-family: 'Open Sans';
  src: url(../public/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  src: url(../public/fonts/OpenSans/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url(../public/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  src: url(../public/fonts/OpenSans/OpenSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url(../public/fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: url(../public/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  src: url(../public/fonts/OpenSans/OpenSans-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'EB Garamond';
  src: url(../public/fonts/EBGaramond/EBGaramond-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 400;
  src: url(../public/fonts/EBGaramond/EBGaramond-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 500;
  src: url(../public/fonts/EBGaramond/EBGaramond-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 600;
  src: url(../public/fonts/EBGaramond/EBGaramond-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 700;
  src: url(../public/fonts/EBGaramond/EBGaramond-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 800;
  src: url(../public/fonts/EBGaramond/EBGaramond-ExtraBold.ttf) format('truetype');
}