@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-text-stroke-width: 0;
  -webkit-font-smoothing: antialiased;
}

/* Outline */
*:focus {
  outline: none;
}

a:focus {
  border: 1px dotted theme(colors.black);
}

input[type='checkbox']:focus,
input[type='radio'].radio-button:focus {
  outline: auto;
}

/* Font */
body,
button,
p,
span,
.dx-theme-generic-typography {
  font-family: theme(fontFamily.body);
}

.text-xs {
  font-size: theme(fontSize.xs) !important;
}
.text-sm {
  font-size: theme(fontSize.sm) !important;
}
.text-base {
  font-size: theme(fontSize.base) !important;
}
.text-lg {
  font-size: theme(fontSize.lg) !important;
}
.text-xl {
  font-size: theme(fontSize.xl) !important;
}
.font-semibold {
  font-weight: theme(fontWeight.semibold) !important;
}
.font-bold {
  font-weight: theme(fontWeight.bold) !important;
}
.font-extrabold {
  font-weight: theme(fontWeight.extrabold) !important;
}

.dx-theme-generic-typography a {
  color: unset;
}

/* Outline */
.dx-texteditor:not(.border-none).dx-state-active.dx-editor-outlined,
.dx-texteditor:not(.border-none).dx-state-focused.dx-editor-outlined {
  border-color: theme(colors.black) !important;
}

/* Print page */
@media print {
  header,
  nav,
  footer,
  .noPrint {
    display: none !important;
  }
}
