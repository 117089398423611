.drpContas {
  border: 1px solid theme(colors.brand.gray300) !important;
}

.drpContas .dx-texteditor-buttons-container {
  float: left;
  order: 1;
}

.drpContas .dx-texteditor-input-container {
  float: right;
  order: 2;
}

.drpContas .dx-texteditor-input {
  padding-left: 15px;
  font-size: 11pt;
}

.drpContas
  .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered)
  .dx-dropdowneditor-icon {
  background-color: theme(colors.brand.gray200);
}

.drpContas .dx-texteditor-input {
  padding: 7px 19px 8px;
  background-color: theme(colors.brand.gray300);
  color: theme(colors.brand.gray400);
  font-weight: 400;
}

.dx-dropdowneditor-icon {
  color: theme(colors.brand.gray400);
}

.drpContas .dx-dropdowneditor-button {
  background-color: theme(colors.brand.gray300);
}

.drpContas .dx-selectbox-container {
  background-color: theme(colors.brand.gray300);
}

.drpContas:hover {
  border: 1px solid theme(borderColor.brand.gray300) !important;
}

.drpContas.dx-texteditor.dx-state-hover {
  border: 1px solid theme(borderColor.brand.gray300) !important;
}

.drpContas.dx-texteditor.dx-state-focused {
  border: 1px solid theme(borderColor.brand.gray300) !important;
}
