.fp-section.fp-table,
.fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  position: relative;
  z-index: 1 !important;
}

.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

footer {
  z-index: 10;
  position: relative;
  padding-top: 30px !important;
  padding-left: 130px;
  padding-right: 130px;
  background-color: theme(colors.brand.gray300);
}

footer .topo {
  /* margin-top: auto; */
  color: theme(colors.black);
}

footer .topo .menuR ul {
  text-align: left;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

footer .nav-link {
  padding: 0 !important;
}

footer .topo .menuR > ul > li > .nav-link {
  color: theme(colors.brand.green600);
  font-size: 25px;
  font-size: 1.5625rem;
  font-family: theme(fontFamily.titulos);
  font-weight: 500;
}

footer .topo .menuR ul .nav-item {
  padding-bottom: 10px;
}

footer .topo .menuR ul .nav-item > ul a,
footer .meio .menuM .nav-item > ul a {
  font-size: 1.25rem;
  text-align: left;
  color: theme(colors.brand.gray500);
  padding-bottom: 5px;
  padding-top: 5px;
}

footer .topo .menuR ul .nav-item > ul a:hover {
  color: theme(colors.brand.green600);
}

@media only screen and (max-width: 768px) {
  footer .topo .menuR ul .nav-item > ul a,
  footer .meio .menuM .nav-item > ul a {
    font-size: 16px;
  }
}

footer .topo .menuR ul .nav-link {
  text-align: left;
  color: theme(colors.brand.gray500);
  /* padding-bottom: 5px;
    padding-top: 5px; */
}

@media only screen and (min-width: 1200px) {
  .container-fluid {
    padding-left: 120px;
    padding-right: 120px;
  }

  footer .topo .menuR ul .nav-link {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1200px) {
  footer .topo .menuR > ul > li > .nav-link {
    font-size: 29px;
    font-size: 1.8125rem;
  }
}

footer .topo .menuR > ul > li {
  margin-bottom: 30px;
}

footer .meio {
  margin-top: 80px;
  color: theme(colors.black);
}

footer .meio .meio-wrapper {
  display: flex;
}

footer .meio .meio-wrapper .meio-sec {
  flex: 1;
}

footer .meio .menuM {
  text-align: left;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

footer .meio .menuM > li {
  /* margin-bottom: 30px; */
}

footer .meio .menuM > li > .nav-link {
  color: theme(colors.brand.green600);
  font-size: 25px;
  font-size: 1.5625rem;
  font-family: theme(fontFamily.titulos);
  font-weight: 500;
}

footer .meio .menuM .nav-link {
  text-align: left;
  color: theme(colors.brand.gray500);
  /* padding: 5px;
padding-bottom: 0px;
padding-top: 0px; */
  font-size: 15px;
  font-size: 0.9375rem;
}

footer .meio .menuM ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

footer .meio .menuM ul {
  display: flex;
  flex-wrap: wrap;
}

footer .meio .menuM > li li {
  position: relative;
  display: inline-block;
}

footer .meio .menuM > li li:not(:last-child) {
  padding-right: 10px;
  margin-right: 5px;
}

footer .meio .menuM > li li:not(:last-child)::after {
  content: '';
  top: 0;
  right: 0;
  position: absolute;
  width: 1px;
  height: 50%;
  bottom: 0;
  border-right: 1px solid theme(colors.brand.gray500);
  margin: auto;
}

@media only screen and (min-width: 1200px) {
  footer .meio .menuM > li > .nav-link {
    font-size: 29px;
    font-size: 1.8125rem;
  }
}

footer .align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

footer .justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

@media only screen and (min-width: 992px) {
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.menuActivo {
  position: relative;
  display: inline-block;
}

.menuActivo > div {
  position: absolute;
  top: -15px;
  right: -10px;
  width: 10px;
  height: 10px;
  background-color: theme(colors.brand.green600);
  border: 1px solid theme(colors.white);
  border-radius: 10px;
}

footer .meio .social {
  text-align: left;
}

.footer-lp .social a {
  color: theme(colors.brand.gray500);
  /* margin-left: 15px; */
  font-size: 18px;
  font-size: 1.125rem;
}

@media only screen and (min-width: 1200px) {
  .footer-lp .social a {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

footer .meio .social a {
  color: theme(colors.brand.gray500);
  /* margin-left: 15px; */
  font-size: 18px;
  font-size: 1.125rem;
}

@media only screen and (min-width: 1200px) {
  footer .meio .social a {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.fab,
.far {
  font-weight: 400;
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

footer .copy {
  color: theme(colors.brand.gray500);
  font-size: 14px;
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 60px;
}

footer .copy .menuP {
  margin-left: 5px;
  display: inline-block;
}

footer .copy .menuP ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

footer .copy .menuP ul li {
  position: relative;
  padding: 0px;
  margin-right: -2px;
  display: inline-block;
}

footer .copy .menuP ul .nav-link {
  /* padding: 0px;
padding-left: 5px;
padding-right: 5px; */
  font-size: 14px;
  font-size: 0.875rem;
  color: theme(colors.brand.gray500);
}

footer .copy .menuP ul li:not(:last-child) {
  padding-right: 10px;
  margin-right: 5px;
}

footer .copy .menuP ul li:not(:last-child)::after {
  content: '';
  top: 0;
  right: 0;
  position: absolute;
  width: 1px;
  height: 100%;
  border-right: 1px solid theme(colors.brand.gray500);
}

footer .copy .nota {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 15px;
}

@media only screen and (max-width: 768px) {
  footer.fp-section,
  footer .fp-tableCell {
    height: auto !important;
    padding-left: 30px;
    padding-right: 30px;
  }

  footer .fp-tableCell {
    padding-bottom: 20px !important;
  }

  footer .topo .menuR > ul {
    flex-wrap: wrap;
  }

  footer .topo .menuR > ul > li {
    flex: auto;
  }

  footer .copy {
    margin-bottom: 0;
    margin-top: 60px;
  }

  footer .meio .meio-wrapper {
    flex-wrap: wrap;
    row-gap: 30px;
  }

  footer .meio .meio-wrapper .meio-sec {
    flex: auto;
  }

  footer .meio .meio-wrapper .meio-sec.empty {
    display: none;
  }

  footer .meio .meio-wrapper .meio-sec.social-media {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  footer .topo .menuR > ul {
    padding-top: 30px;
    flex-direction: column;
  }

  footer .meio {
    margin-top: 0px;
  }

  footer .meio .meio-wrapper {
    flex-direction: column;
  }

  footer .meio .meio-wrapper .meio-sec {
    margin-bottom: 30px;
    width: 100%;
  }

  footer .copy {
    /* padding: 0 15px; */
    margin-bottom: 30px;
  }
}

.fa-linkedin:before {
  content: '\f0e1';
}

.fa-twitter:before {
  content: '\f099';
}

.fa-facebook:before {
  content: '\f39e';
}

.fa-instagram:before {
  content: '\f16d';
}

.fa-youtube:before {
  content: '\f167';
}

.fa-wordpress:before {
  content: '\f411';
}

.fa-whatsapp:before {
  content: '\f40c';
}
