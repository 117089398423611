#user-forms-verification-dialog {
  line-height: theme(lineHeight.normal);
  z-index: theme(zIndex.50);
}

#user-forms-verification-dialog p:not(even) {
  margin-top: theme(margin.3);
}

#user-forms-verification-dialog a {
  font-weight: theme(fontWeight.medium);
  color: theme(textColor.brand.green500);
  text-decoration: underline;
  width: fit-content;
}

#user-forms-verification-dialog a:hover {
  opacity: 0.75;
}

#user-forms-verification-dialog a:focus {
  outline: none;
}
