button.custom-button {
  align-items: center;
  display: flex;
  font-size: theme(fontSize.sm);
  gap: theme(gap.1);
  padding: theme(padding.2) theme(padding.4);
}

button.custom-button:not([class*='border-']) {
  border: 1px solid transparent;
}

button.custom-button:not([class*='justify-']) {
  justify-content: center;
}

button.custom-button:hover:not(:disabled, .link-text, [class*='hover:opacity-']) {
  opacity: 0.75;
}

button.custom-button > i {
  line-height: theme(lineHeight.5);
}

button.custom-button > span {
  line-height: theme(lineHeight.5);
}

button.custom-button
  > span:not(.text-start, .text-end, .text-right, .text-center, .text-left) {
  text-align: justify;
}

button.custom-button.hidden {
  visibility: hidden !important;
}

button.custom-button.primary {
  border-color: theme(colors.brand.green600);
  color: theme(colors.brand.green600);
}

button.custom-button.primary:hover:not(:disabled) {
  background-color: theme(colors.brand.green600);
  color: theme(colors.white) !important;
  opacity: 1;
  transition: 0.1s;
}

button.custom-button.danger {
  background-color: unset;
  border-color: theme(colors.brand.negative_number);
  color: theme(colors.brand.negative_number);
}

button.custom-button.danger:hover {
  background-color: theme(colors.brand.negative_number);
  color: theme(colors.brand.gray100);
  opacity: 1;
}

button.custom-button.primary > span:not(.uppercase, .capitalize, .normal-case),
button.custom-button.danger > span:not(.uppercase, .capitalize, .normal-case) {
  text-transform: capitalize;
}

button.custom-button.link {
  color: theme(colors.brand.green300);
}

button.custom-button.link:disabled {
  background-color: transparent;
  border-color: transparent;
  color: theme(colors.brand.gray100);
  cursor: not-allowed;
}

button.custom-button.link:disabled > span {
  color: theme(colors.brand.gray300);
}

button.custom-button.icon-button {
  display: inline-flex;
}

button.custom-button.icon-button > span {
  line-height: 1;
}

button.custom-button.link > span:not(.no-underline) {
  text-decoration: underline;
  white-space: normal;
}

button.custom-button.link-text > span {
  color: theme(colors.brand.green500);
  font-weight: theme(fontWeight.semibold);
  white-space: normal;
}

button.custom-button.link-text:hover > span:not(.no-underline) {
  text-decoration: underline;
}

button.custom-button.back-button {
  font-size: theme(fontSize.base);
  font-weight: theme(fontWeight.bold);
  color: theme(colors.brand.green600);
}

button.custom-button.back-button > span {
  border-bottom: 2px solid;
  padding-bottom: 2px;
}

button.custom-button.icon-button,
button.custom-button.link,
button.custom-button.link-text {
  min-width: unset;
  padding: 0;
}

button.custom-button.small {
  padding: theme(padding.1) theme(padding.2);
}

button.custom-button.small > i {
  font-size: theme(fontSize.xs);
}

button.custom-button:disabled {
  background-color: theme(colors.brand.gray350);
  border-color: transparent;
  color: theme(colors.brand.gray100);
  cursor: not-allowed;
}

button.custom-button:focus-visible {
  border-color: black !important;
}
