#login-language-select {
  background-color: theme(colors.transparent);
}

#login-language-select input,
#login-language-select .dx-dropdowneditor-icon {
  color: theme(colors.white);
}

#login-language-select.dx-state-hover,
#login-language-select.dx-state-focused {
  border-color: theme(colors.white);
}
